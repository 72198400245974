// ----------------------------
// 注意：p-accordion.html.erbは存在しない
// 開閉のためだけのJS
// ----------------------------

// ----------------------------
// アコーディオンメニューの開閉
// ----------------------------
$(document).on('click', '.js-accordion', function() {
  $(this).find(".js-accordionIcon").toggleClass("is-active");
  $(this).next('.js-accordionContent').slideToggle();
});
