const uploaders = document.getElementsByClassName('js-fileUploader');
const uploaderInputs = document.getElementsByClassName('js-fileUploader_input');

// required属性の変更前の値を保存しておくためのMapオブジェクト
const originalRequiredValues = new Map();

// ============================================================
// 発火・イベントの追加
// ============================================================

// ------------------------------
// ページ読み込み時、cache用のinputに値が入っているかを確認し、
// 値が存在する場合はプレビュー表示、メインのinputのrequiredを解除
// （cacheにファイルが入っているため、ファイルが送信されることは担保されている）
// ------------------------------
document.addEventListener('DOMContentLoaded', function () {
  const uploaderWraps = document.getElementsByClassName('js-fileUploader_wrap');
  Array.from(uploaderWraps).forEach(function (uploaderWrap) {
    // required属性の変更前の値を保存
    saveOriginalRequiredValue(uploaderWrap)

    const uploadedCacheFile = uploaderWrap.querySelector('.js-fileUploader_cache').value;
    if (uploadedCacheFile) {
      displayUploadedFile(uploaderWrap, uploadedCacheFile);
      uploaderWrap.querySelector('.js-fileUploader_input').required = false;
    }
  });
});

// ------------------------------
// ページ読み込み時、ファイルが既に保存されているかを確認し、
// 値が存在する場合はプレビュー表示、メインのinputのrequiredを解除
// （cacheにファイルが入っているため、ファイルが送信されることは担保されている）
// ------------------------------
document.addEventListener('DOMContentLoaded', function () {
  const uploaderWraps = document.getElementsByClassName('js-fileUploader_wrap');
  Array.from(uploaderWraps).forEach(function (uploaderWrap) {
    // required属性の変更前の値を保存
    saveOriginalRequiredValue(uploaderWrap)

    const previewImgElementSource = uploaderWrap.querySelector('.js-fileUploader_preview_img').getAttribute('src');
    const downloadElementSource = uploaderWrap.querySelector('.js-fileUploader_download').innerHTML.replace(/\r?\n/g, '').trim();
    if (previewImgElementSource) {
      displayUploadedFile(uploaderWrap, previewImgElementSource);
      uploaderWrap.querySelector('.js-fileUploader_input').required = false;
    } else if (downloadElementSource) {
      displayUploadedFile(uploaderWrap, downloadElementSource);
      uploaderWrap.querySelector('.js-fileUploader_input').required = false;
    }
  });
});

// ------------------------------
// ファイルがアップロードされた場合、プレビューを表示
// ------------------------------
document.addEventListener('DOMContentLoaded', function () {
  Array.from(uploaderInputs).forEach(function (uploaderInputs) {
    uploaderInputs.addEventListener('change', function () {
      displayUploadedFile(this.closest('.c-fileUploader_wrap'), this.files[0]);
    });
  });
});

// ------------------------------
// 削除ボタンがクリックされた場合、アップロード前の状態に戻す
// ------------------------------
document.addEventListener('DOMContentLoaded', function () {
  const deleteBtnLinks = document.getElementsByClassName('js-fileUploader_deleteBtn_link');

  if (deleteBtnLinks) {
    Array.from(deleteBtnLinks).forEach(function (deleteBtnLink) {
      deleteBtnLink.addEventListener('click', function (event) {
        event.preventDefault();

        deleteBtnLink.classList.add('disabled');

        let message = deleteBtnLink.getAttribute('data-message');
        if (window.confirm(message)) {
          // 入力必須でない場合、フォーム送信時にファイルが削除されるように削除フラグを有効にする（CarrierWave用）
          enableRemoveFileFlag(deleteBtnLink.closest('.js-fileUploader_wrap'));

          // アップロード前の状態に戻す
          resetFileUploaderToDefault(deleteBtnLink);
        } else {
          deleteBtnLink.classList.remove('disabled');
        }
      });
    });
  }
});

// ============================================================
// 内部的な処理
// ============================================================

// ------------------------------
// アップロードされたファイルの種類を判別（画像 or PDF）し、プレビューを表示するメソッド
// 引数：対象の要素のwrap, 表示するファイルのinput
// ------------------------------
function displayUploadedFile(wrap, file) {
  if (!file) return;

  // 初期表示案内、プレビュー、ダウンロードリンクを非表示
  wrap.querySelector('.js-fileUploader_initial').style.display = 'none';
  wrap.querySelector('.js-fileUploader_preview').style.display = 'none';
  wrap.querySelector('.js-fileUploader_download').style.display = 'none';

  // 削除ボタンが存在すれば非表示
  hideDeleteButton(wrap);

  // アップロードされたファイルが画像かPDFかを判別
  if (typeof file === 'string') { // 「file」が文字列の場合（DBに保存済み、または、cacheの値）、拡張子で判別
    const file_extension = file.split('?')[0].split('.').pop();
    if (['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF'].includes(file_extension)) {
      displayPreview(wrap, file);
    } else if (file_extension === 'pdf') {
      displayDownloadLink(wrap, file);
    }
  } else { // 「file」が文字列以外の場合（input[type="file"]でアップロードされた場合）、typeで判別
    if (['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      displayPreview(wrap, file);
    } else if (file.type === 'application/pdf') {
      displayDownloadLink(wrap, file);
    }
  }

  // ファイルの削除フラグを無効にする（削除ボタンのクリック後に再アップロードする場合を考慮）
  disableRemoveFileFlag(wrap);
}

// ------------------------------
// プレビューを表示するメソッド
// 引数：対象の要素のwrap, 表示するファイルのinput
// ------------------------------
function displayPreview(wrap, file) {
  // アップロードされた画像のプレビューを出力
  // （input[type="file"]でアップロードされた場合のみ対応。過去にDBに保存されたもの、cacheの表示はerb上に記載）
  if (typeof file === 'object') {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (function () {
      wrap.querySelector('.js-fileUploader_preview_img').src = fileReader.result;
    });
  }

  // アップロードされたファイル名を出力
  wrap.querySelector('.js-fileUploader_preview_title').innerHTML = typeof file === 'object' ? file.name : file.split("/").slice(-1)[0].split('?')[0];

  // プレビュー項目を表示
  wrap.querySelector('.js-fileUploader_preview').style.display = 'flex';

  // 削除ボタンが存在すれば表示
  showDeleteButton(wrap);
}

// ------------------------------
// ダウンロードリンクを表示するメソッド
// 引数：対象の要素のwrap, 表示するファイルのinput
// ------------------------------
function displayDownloadLink(wrap, file) {
  // アップロードされたPDFのダウンロードリンクを出力
  // （input[type="file"]でアップロードされた場合のみ対応。過去にDBに保存されたもの、cacheの表示はerb上に記載）
  const downloadLinkElement = wrap.querySelector('.js-fileUploader_download');
  if (typeof file === 'object') {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (function () {
      downloadLinkElement.href = fileReader.result;
      downloadLinkElement.download = file.name;
      downloadLinkElement.textContent = file.name;
    });
  }

  // ダウンロードリンクを表示
  downloadLinkElement.style.display = 'flex';

  // 削除ボタンが存在すれば表示
  showDeleteButton(wrap);
}

// ------------------------------
// required属性の変更前の値を保存するメソッド
// 引数：対象の要素のwrap
// ------------------------------
function saveOriginalRequiredValue(wrap) {
  const inputElement = wrap.querySelector('.js-fileUploader_input');
  originalRequiredValues.set(wrap, inputElement.required);
}

// ------------------------------
// アップロード前の状態に戻すメソッド
// 引数：クリックされた削除ボタン
// ------------------------------
function resetFileUploaderToDefault(deleteBtnLink) {
  const closestWrap = deleteBtnLink.closest('.js-fileUploader_wrap');

  // プレビュー、ダウンロードリンク、削除ボタンを非表示、初期表示案内を表示
  closestWrap.querySelector('.js-fileUploader_preview').style.display = 'none';
  closestWrap.querySelector('.js-fileUploader_download').style.display = 'none';
  closestWrap.querySelector('.js-fileUploader_deleteBtn').style.display = 'none';
  closestWrap.querySelector('.js-fileUploader_initial').style.display = 'flex';

  // input要素の内容をクリア
  closestWrap.querySelector('.js-fileUploader_input').value = '';

  // キャッシュの内容もクリア
  closestWrap.querySelector('.js-fileUploader_cache').value = '';

  // 変更前のrequired属性の値を再設定
  const originalRequiredValue = originalRequiredValues.get(closestWrap);
  closestWrap.querySelector('.js-fileUploader_input').required = originalRequiredValue;
}

// ------------------------------
// 削除ボタンが存在すれば表示するメソッド
// 引数：対象の要素のwrap
// ------------------------------
function showDeleteButton(wrap) {
  const deleteBtn = wrap.querySelector('.js-fileUploader_deleteBtn');
  if (deleteBtn) {
    deleteBtn.style.display = 'flex';
  }
}

// ------------------------------
// 削除ボタンが存在すれば非表示にするメソッド
// 引数：対象の要素のwrap
// ------------------------------
function hideDeleteButton(wrap) {
  const deleteBtn = wrap.querySelector('.js-fileUploader_deleteBtn');
  if (deleteBtn) {
    deleteBtn.style.display = 'none';
  }
}

// ------------------------------
// ファイルの削除フラグを有効にするメソッド（CarrierWave用）
// 引数：対象の要素のwrap
// ------------------------------
function enableRemoveFileFlag(wrap) {
  const removeFileFlag = wrap.querySelector('.js-fileUploader_removeFileFrag');
  if (removeFileFlag) {
    removeFileFlag.value = '1';
  }
}

// ------------------------------
// ファイルの削除フラグを無効にするメソッド（CarrierWave用）
// 引数：対象の要素のwrap
// ------------------------------
function disableRemoveFileFlag(wrap) {
  const removeFileFlag = wrap.querySelector('.js-fileUploader_removeFileFrag');
  if (removeFileFlag) {
    removeFileFlag.value = '0';
  }
}

// ============================================================
// 外観・アニメーション
// ============================================================

// ------------------------------
// アップローダー上にファイルがドラッグされた場合、アップローダーの背景色を変更
// ------------------------------
document.addEventListener('DOMContentLoaded', function () {
  Array.from(uploaders).forEach(function (uploaders) {
    uploaders.addEventListener('dragover', function () {
      event.preventDefault();
      this.style.backgroundColor = '#eeeeee';
    });
  });
});

// ------------------------------
// アップローダー上にドラッグされたファイルが離れた場合、アップローダーの背景色を再変更
// ------------------------------
document.addEventListener('DOMContentLoaded', function () {
  Array.from(uploaders).forEach(function (uploaders) {
    uploaders.addEventListener('dragleave', function () {
      this.style.backgroundColor = '';
    });
  });
});

// ------------------------------
// アップローダー上にファイルがドロップされた場合、アップローダーの背景色を再変更
// ------------------------------
document.addEventListener('DOMContentLoaded', function () {
  Array.from(uploaders).forEach(function (uploaders) {
    uploaders.addEventListener('drop', function () {
      event.preventDefault();
      this.style.backgroundColor = '';
      if (event.dataTransfer.files.length > 0) {
        const uploaderInput = this.getElementsByClassName('js-fileUploader_input')[0];
        uploaderInput.files = event.dataTransfer.files;
        uploaderInput.dispatchEvent(new Event('change'));
      }
    });
  });
});
