import zxcvbn from 'zxcvbn';

document.addEventListener('DOMContentLoaded', function() {

  // ------------------------------
  // パスワードの表示・非表示の切り替え
  // ------------------------------
  const inputPasswordSwitchBtns = document.getElementsByClassName('js-inputPasswordSwitchBtn');

  Array.from(inputPasswordSwitchBtns).forEach(function(btn) {
    btn.addEventListener('click', function () {
      const parent = btn.closest('.js-inputPasswordSwitch');
      const input = parent.querySelector('.js-inputPasswordSwitchInput');

      if (input.type == 'password') {
        input.type = 'text';
        btn.querySelector('.js-inputPasswordSwitchBtnEye').style.display = 'none';
        btn.querySelector('.js-inputPasswordSwitchBtnSlash').style.display = 'block';
      } else {
        input.type = 'password';
        btn.querySelector('.js-inputPasswordSwitchBtnEye').style.display = 'block';
        btn.querySelector('.js-inputPasswordSwitchBtnSlash').style.display = 'none';
      }
    });
  });

  // ------------------------------
  // フォームの送信時、パスワード入力欄の type を password に変更する
  // ------------------------------
  document.addEventListener('DOMContentLoaded', function() {
    const form = document.querySelector('.js-inputPasswordSwitchForm');

    form.addEventListener('submit', function() {
      const inputs = form.querySelectorAll('.js-inputPasswordSwitchInput');
      inputs.forEach(input => {
        input.type = 'password';
      });
    });
  });

  // ------------------------------
  // パスワード強度の判定・表示
  // ------------------------------
  function displayPasswordStrength(input) {
    // パスワード強度が非表示に設定されているならば終了する
    const strength = input.closest('.js-inputPasswordSwitch').querySelector('.js-inputPasswordStrength');
    if (strength === null) return false;

    // 強度スコアに応じた表示テキスト・色の設定
    let evaluationTxt;
    let meterUnitColor;
    let meterUnitCount;

    if (input.value != "") {
      // パスワードが入力されている場合、パスワード強度を算出（zxcvbn.js）
      const result = zxcvbn(input.value);

      switch (result.score) {
        case 0:
          evaluationTxt = '危険';
          meterUnitColor = '#EF5350';
          meterUnitCount = 1;
          break;
        case 1:
          evaluationTxt = 'とても推測しやすい';
          meterUnitColor = '#EF5350';
          meterUnitCount = 2;
          break;
        case 2:
          evaluationTxt = '推測しやすい';
          meterUnitColor = '#FFEE58';
          meterUnitCount = 3;
          break;
        case 3:
          evaluationTxt = '推測しにくい';
          meterUnitColor = '#66BB6A';
          meterUnitCount = 4;
          break;
        case 4:
          evaluationTxt = 'とても推測しにくい';
          meterUnitColor = '#66BB6A';
          meterUnitCount = 5;
          break;
      }
    } else {
      // パスワードが入力されていない場合、空白になるよう設定
      evaluationTxt = '';
      meterUnitColor = '#b8b8b8';
      meterUnitCount = 0;
    }

    // 強度テキストの表示
    strength.querySelector('.js-inputPasswordStrengthTxtVal').innerText = evaluationTxt;

    // 強度メーターの表示（色変え）
    let meterUnits = strength.querySelectorAll('.js-inputPasswordStrengthMeterUnit');
    meterUnits.forEach(meterUnit => {
      meterUnit.style.backgroundColor = '#b8b8b8';
    });
    for (var i = 1; i <= meterUnitCount; i++) {
      meterUnits[i-1].style.backgroundColor = meterUnitColor;
    }
  }

  const inputPasswordSwitchInputs = document.getElementsByClassName('js-inputPasswordSwitchInput');
  Array.from(inputPasswordSwitchInputs).forEach(function(input) {
    input.addEventListener('input', function() {
      displayPasswordStrength(input);
    });
  });
});
