// ----------------------------
// 「プロジェクト詳細」のh1タグを取得し、idを付与、目次を作成
// ----------------------------
$(document).ready(function() {
  let mainSubIndexStr = "";
  let mainIndexContentStr = "";

  $('.js-projectDetailsMainTxt h1').each(function(n) {
    const indexId = "index" + n;
    $(this).attr("id", indexId);
    mainSubIndexStr += "<a class='p-projectDetailsMain_sub_index_item' href='#" + indexId + "'>" + $(this).text() + "</a>";
    mainIndexContentStr += "<a class='p-projectDetailsMain_index_content_item' href='#" + indexId + "'>" + $(this).text() + "</a>";
  });

  if (mainSubIndexStr.length != 0) {
    $('.js-projectDetailsMainSubIndex').append(mainSubIndexStr);
    $('.js-projectDetailsMainIndexContent').append(mainIndexContentStr);
  } else {
    $('.js-projectDetailsMainSubIndex').css('display','none');
    $('.js-projectDetailsMainIndex').css('display','none');
  }
});

// ----------------------------
// 目次のリンクのクリック時、ページ内遷移（スムーススクロール）
// ----------------------------
$(function() {
  const headerHeight = $('.js-header').outerHeight() + 10;
  const urlHash = location.hash;
  if(urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function() {
      const target = $(urlHash);
      const position = target.offset().top - headerHeight;
      $('body,html').stop().animate({scrollTop:position}, 300);
    }, 100);
  }
  $('a[href^="#"]').click(function() {
    const href= $(this).attr("href");
    if (href === '#') return;

    const target = $(href);
    const position = target.offset().top - headerHeight;
    $('body,html').stop().animate({scrollTop:position}, 300);
    return true;
  });
});
