window.onload=function() {
  const btns = document.getElementsByClassName('js-transitionTopBtn');
  const scrollToOptions = {
    top : 0,
    behavior : 'smooth'
  };
  
  for (let i = 0; i < btns.length; i++) {
    btns[i].addEventListener('click', function() {
      window.scrollTo(scrollToOptions);
    });
  }
}