// ------------------------------
// 「絞り込み」ボタンクリックでサイドバーを開く処理
// ------------------------------
$(document).on('click', '.js-projectArchiveSidebarToggle', function() {
    if ($(".js-projectArchiveSidebar").hasClass('is-display')) {
        bodyScrollToggle(false);
    } else {
        bodyScrollToggle(true);
    }

    $(".js-projectArchiveSidebar").toggleClass("is-display");
    $(".js-projectArchiveSidebarBg").toggleClass("is-display");
});

// ------------------------------
// 「閉じる」ボタンクリックでサイドバーを閉じる処理
// ------------------------------
$(document).on('click', '.js-projectArchiveSidebarClose', function() {
  bodyScrollToggle(false);
  $(".js-projectArchiveSidebar").removeClass("is-display");
  $(".js-projectArchiveSidebarBg").removeClass("is-display");
});

// ------------------------------
// 半透明色の背景 クリックでサイドバーと共に閉じる処理
// ------------------------------
$(document).on('click', '.js-projectArchiveSidebarBg', function() {
    bodyScrollToggle(false);
    $(".js-projectArchiveSidebar").removeClass("is-display");
    $(".js-projectArchiveSidebarBg").removeClass("is-display");
});

// ----------------------------------
// bodyのスクロールを禁止・禁止解除する
// ----------------------------------
function bodyScrollToggle(flag) {
    let scrollPosition; // モーダルを開く前のbodyのスクロール位置
    const body = document.getElementsByTagName('body')[0]; // bodyタグ
    const scrollBarWidth = window.innerWidth - document.body.clientWidth; // スクロールバーの幅
  
    // iOS もしくは iPadOS を使っている場合の判別（iOS・iPadOSのSafariのみ oveflow: hidden; によるスクロール禁止が適用できないため）
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isiOS = userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document;
  
    if (flag) { // bodyのスクロールを禁止する処理
      body.style.paddingRight = scrollBarWidth + 'px'; // 非表示にされるスクロールバーの分、右側に余白を追加する（box-sizingの値に注意）
      if (isiOS) {
        scrollPosition = -window.pageYOffset;
        body.style.position = 'fixed';
        body.style.width = '100%';
        body.style.top = scrollPosition + 'px';
      } else {
        body.style.overflow = 'hidden';
      }
    } else { // bodyのスクロール禁止を解除する処理
      if (isiOS) {
        scrollPosition = parseInt(body.style.top.replace(/[^0-9]/g,''));
        body.style.position = '';
        body.style.width = '';
        body.style.top = '';
        body.style.paddingRight = ''; // 消したスクロールバーの分だけ追加した右側の余白を削除
        window.scrollTo(0, scrollPosition);
      } else {
        window.setTimeout(function(){
          body.style.overflow = '';
          body.style.paddingRight = ''; // 消したスクロールバーの分だけ追加した右側の余白を削除
        }, 150);
      }
    }
  }