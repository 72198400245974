// ----------------------------
// 注意
// 項目は「プロジェクト詳細」「ニュース」「出資者向け書類」「IR情報」のみを想定
// 変更が必要の際は、erbにも変更を加えるようにしてください
// ----------------------------

// ----------------------------
// ページ読み込み時、「プロジェクト詳細」をactiveに変更
// ----------------------------
$(document).ready(function() {
    const detailTab = $('.js-projectDetail_tab-detail');
    const detailInput = $('.js-projectDetail_input-detail');
    const detailContent = $('.js-projectDetail_content-detail');

    detailTab.addClass('is-visiting');
    detailInput.prop('checked', true);
    detailContent.addClass('is-visiting');
});

// ----------------------------
// タブボタンをクリック時、クリックされた項目をactiveに変更
// ----------------------------
$(document).on('click', 'input[name=projectTab]', function() {
    $('.js-projectDetail_tab').removeClass('is-visiting');
    $('.js-projectDetail_input').prop('checked', false);
    $('.js-projectDetail_content').removeClass('is-visiting');

    $('.js-projectDetail_tab-' + $(this).val()).addClass('is-visiting');
    $('.js-projectDetail_input-' + $(this).val()).prop('checked', true);
    $('.js-projectDetail_content-' + $(this).val()).addClass('is-visiting');
});