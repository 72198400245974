import FroalaEditor from 'froala-editor/js/froala_editor.min.js';
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/char_counter.min.js'
import 'froala-editor/js/plugins/code_beautifier.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/help.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/image_manager.min.js'
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/inline_style.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/js/plugins/line_height.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/print.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/save.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/video.min.js'
global.FroalaEditor = FroalaEditor;

import '@fortawesome/fontawesome-free/js/all'

import 'zxcvbn'

import '../javascripts/layouts/l-sidebar'

import '../javascripts/projects/p-accordion'
import '../javascripts/projects/p-projectArchiveSidebar'
import '../javascripts/projects/p-projectDetails'
import '../javascripts/projects/p-projectDetailsMain'

import '../javascripts/components/c-fileUploader'
import '../javascripts/components/c-transitionTopBtn'
import '../javascripts/components/c-inputPasswordSwitch'

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require("@nathanvda/cocoon")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.


const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
